import axios from 'axios';
import trestAppConfig from '../../config/trestAppConfig';
import { IUserModel } from '../data/user';
// import { getBrowser } from '../../helpers/helpers';

export async function prepareSW(user: Partial<IUserModel>) {
    if (('serviceWorker' in navigator) == false) {
        console.log('Service worker does not supported');
        return;
    }

    try {
        let serviceWorker: ServiceWorker | null = null;
        let swjsPath = `${process.env.PUBLIC_URL}/sw-trest.js`;
        let reg = await navigator.serviceWorker.register(swjsPath, {scope: "/", updateViaCache: "none" });
        if (reg.installing) {
            serviceWorker = reg.installing;
            // console.log('Service worker installing');
        } else if (reg.waiting) {
            serviceWorker = reg.waiting;
            // console.log('Service worker installed & waiting');
        } else if (reg.active) {
            serviceWorker = reg.active;
            // console.log('Service worker active');
        }

        if (serviceWorker) {
            // console.log("sw current state", serviceWorker.state);
            if (serviceWorker.state == "activated") {
                //If push subscription wasnt done yet have to do here
                // console.log("sw already activated - Do watever needed here");
                return await subscribe(reg, user);
            }
            return new Promise<boolean>((resolve) => {
                serviceWorker?.addEventListener("statechange", async (e) => {
                    // console.log("sw statechange : ", (e.target as ServiceWorker)?.state);
                    if ((e.target as ServiceWorker)?.state === "activated") {
                        // console.log("Just now activated. now we can subscribe for push notification");
                        const regSuccess = await subscribe(reg, user);
                        resolve(regSuccess); 
                    }
                });
            });
        }
    } catch (err) {
        console.log("Service worker failed to register. ", err);
        return false;
    }
}

export async function getSubscription() {
    if (('serviceWorker' in navigator) == false) {
        console.log('Service worker does not supported');
        return;
    }
    let reg = await navigator.serviceWorker.getRegistration();
    if (reg !== undefined) {
        return await reg.pushManager.getSubscription();
    }
    return reg;
}

export async function subscribe(serviceWorkerReg: any, user: Partial<IUserModel>) {
    let subscription = await serviceWorkerReg.pushManager.getSubscription();
    if (subscription === null) {
        try {
            subscription = await serviceWorkerReg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
            });
            const header = {
                'Authorization': trestAppConfig.authorization,
                'Content-Type': 'application/json; charset=utf-8',
                'token': user.token
            }
            const browserSubscription = {
                subscription: subscription,
                token: user.token,
            }
            let response = await axios.post(`${trestAppConfig.pushServer}/notification/register`, browserSubscription, { headers: header });
            if (response.data.status == false) {
                console.log(response);
                return false;
            }
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    } else {
        return true;
    }
}

export async function handlePush(subscription: any, user: Partial<IUserModel>) {
    try {
        const header = {
            'Authorization': trestAppConfig.authorization,
            'Content-Type': 'application/json; charset=utf-8'
        }
        let objPush = {
            expirationTime: null,
            token: user.token,
            endpoint: subscription.endpoint,
            p256dh: process.env.REACT_APP_PUBLIC_VAPID_KEY,
            auth: trestAppConfig.authorization,
            notification: {
                title: 'Push Push Push Push..',
                options: {
                    body: 'Push me, and then just touch me, till I can get my, notification!',
                    icon: './logo192.png',
                    badge: './favicon.ico',
                    vibrate: [100, 50, 100],
                    // tag: "vibration-sample",
                    actions: [
                        {
                            action: "explore", 
                            title: "Go interact with this!",
                            icon: "images/checkmark.png"
                        },
                        {
                            action: "close", 
                            title: "Ignore",
                            icon: "images/red_x.png"
                        },
                    ]
                }
            }
        };
        await axios.post(`${trestAppConfig.pushServer}/api/platform/push`, objPush, { headers: header });
        if (navigator.setAppBadge) {
            let unreadCount = 42;
            navigator.setAppBadge(unreadCount).catch((error) => {
                console.log(error)
            });
        }
    } catch (err) {
        console.error(err);
    }
}

export async function unregister(user: Partial<IUserModel>) {
    const header = {
        'Authorization': trestAppConfig.authorization,
        'Content-Type': 'application/json; charset=utf-8',
        'token': user.token
    }
    const unReg = navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
            registration.unregister();
        }
    });
    if (navigator.clearAppBadge) {
        navigator.clearAppBadge().catch((error) => {
            console.log(error)
        });
    }
    try {
        await axios.post(`${trestAppConfig.pushServer}/notification/remove/all`, {}, { headers: header });
    } catch (err) {
        console.log(err);
    }
    return unReg
}