import React from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Footer from '../../../layout/Footer/Footer';
import logoimg from '../../../assets/logos/trest.ai-logo-800.png'

const DefaultFooter = () => {
	const { darkModeStatus } = useDarkMode();

	return (
		<Footer>
			<div className='container-fluid disclaimer-footer'>
				<div className='row'>
					<span className='text-center'>
						<a href="https://trest.ai/terms-policy#sec_disclaimer" target='_blank' >Disclaimer</a>: trest.ai shares data analysis results, not financial advice. Crypto trading is risky; past performance doesn't guarantee future results.
					</span>
				</div>
				<div className='row border-top'>
					<div className='col'>
						<span className='fw-light'>Copyright © {new Date().getFullYear()} - v1.0.0</span>
					</div>
					<div className='col-auto'>
						<img className='rounded-circle me-2' src={logoimg} width={16} height={16} />
						<a
							href='/'
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<small className='fw-bold'>trest.ai</small>
						</a>
					</div>
				</div>
			</div>
		</Footer>
	);
};

export default DefaultFooter;
