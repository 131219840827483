import { TColor } from "../type/color-type";

export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export function getBrowser() {
	// @ts-ignore
	var isChromium = window.chrome;
	var winNav = window.navigator;
	var vendorName = winNav.vendor;
	// @ts-ignore
	var isOpera = typeof window.opr !== "undefined";
	var isFirefox = winNav.userAgent.indexOf("Firefox") > -1;
	var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
	var isIOSChrome = winNav.userAgent.match("CriOS");
	// @ts-ignore
	var isGoogleChrome = (typeof winNav.userAgentData !== "undefined") ? winNav.userAgentData.brands[2].brand === "Google Chrome" :  vendorName === "Google Inc.";

	if (isIOSChrome) {
		// is Google Chrome on IOS
		return 'chrome';
	} else if(
		isChromium !== null &&
		typeof isChromium !== "undefined" &&
		vendorName === "Google Inc." &&
		isOpera === false &&
		isIEedge === false &&
		isGoogleChrome
	) {
		return 'chrome';
	} else { 
		if (isFirefox) {
			return 'firefox';
		} else if (isIEedge) {
			return 'edge';
		} else if (isOpera) {
			return 'opera';
		} else if (!isGoogleChrome && vendorName === "Google Inc.") {
			return 'chrome';
		} else {
			return undefined;
		}
	}
}

export const theBrowserLanguage = () => { 
	// @ts-ignore
	return navigator.language || navigator.userLanguage || 'en-US';
};

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];
	const color = Math.floor(Math.random() * colors.length);
	return colors[color];
};

export const priceFormat = (price: number) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const formatCurrency = (price: number | string, decimalPoints?: number): string => {
	if (isNaN(Number(price)))
		return "NaN";
	if (decimalPoints !== undefined) {
		return Number(price).toFixed(decimalPoints).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	} else {
		const max_decimal = 12;
        const valueStr = Number(price).toFixed(32).toString();
        const parts = valueStr.split(".");

        if (parts.length === 1) {
            return valueStr;
        }

        const decimalPart = parts[1];
        let nonZeroIndex = -1;
        for (let i = 0; i < Math.min(decimalPart.length, max_decimal); i++) {
            if (decimalPart[i] !== "0") {
                nonZeroIndex = i;
                break;
            }
        }

        if (nonZeroIndex === -1) {
            return Math.round(Number(price)).toString();
        }

        const roundingPosition = Math.min(nonZeroIndex + 1, max_decimal);
        return Number(price).toFixed(roundingPosition);
	}
}

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const priceColor = (curValue: number, prevValue: number | undefined): TColor => {
	if (prevValue) {
		if (curValue > prevValue) return 'success';
		if (curValue < prevValue) return 'danger';
	}
	return 'transparent';
}

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const obscureText = (text: string, beginLetters = 2, endLetters = 2): string => {
	if (text.length <= (beginLetters + endLetters)) {
        return text; 
    }
    const first = text.slice(0, beginLetters);
    const last = text.slice(-endLetters);
    const obscuredMiddle = '*'.repeat(text.length - 4);
    return `${first}${obscuredMiddle}${last}`;
}


export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const addDays = (originalDate: Date, days: number) => {;
    let result = new Date(originalDate);
	result.setDate(result.getDate() + days);
	return result;
}