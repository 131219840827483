import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
// import classNames from 'classnames';
import { useTour } from '@reactour/tour';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
// import OffCanvas, {
// 	OffCanvasBody,
// 	OffCanvasHeader,
// 	OffCanvasTitle,
// } from '../../../components/bootstrap/OffCanvas';
// import Dropdown, {
// 	DropdownItem,
// 	DropdownMenu,
// 	DropdownToggle,
// } from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
// import LANG, { getLangWithKey, ILang } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import { getSubscription, prepareSW, unregister } from '../../../common/function/pushNotification';
import AuthContext from '../../../contexts/authContext';
import { appPagesMenu } from '../../../menu';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../../components/ConfirmModal';
import { logout } from '../../../common/function/auth/auth';

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	const navigate = useNavigate();
	const { mobileDesign } = useContext(ThemeContext);
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { userData, setUser, setUserData } = useContext(AuthContext);

	if (!userData.token) {
        navigate(`../../${appPagesMenu.login.path}`);
        return;
    }

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	// const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const [notificationStatus, setNotificationStatus] = useState(false);

	const [modalTitle, setModalTitle] = useState('');
	const [modalMessage, setModalMessage] = useState('');
	const [showModal, setShowModal] = useState<boolean>(false);

	const handleNotificationClick = async () => {
		// setOffcanvasStatus(true);
		if(notificationStatus === true) {
			setModalTitle('Please confirm');
			setModalMessage('Disabling notifications may cause missing opportunities, are you sure?');
			setShowModal(true);
		} else {
			handleSubscribe();
		}
	}

	const getSubscriptionStatus  = async () => {
		if(userData.notificationDevices) {
			let pushsub = await getSubscription();
			if(pushsub && pushsub.endpoint) {
				if(userData.notificationDevices?.findIndex((item) => item === pushsub?.endpoint) >= 0)
					setNotificationStatus(true);
			}
		}
	}

	let psFlag = false;
	useEffect(() => {
		if(!psFlag) {
			getSubscriptionStatus();
		}
		return () => { psFlag = true }
	}, [notificationStatus]);

	const handleSubscribe = async () => {
        try {
			var notificationSupported = 'Notification' in window;
			if (notificationSupported) {
				const permission = await window.Notification.requestPermission();
				if (permission !== 'granted') {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Missing Permissions</span>
						</span>,
						`Please grant the browser permission to get notifications on this device`,
						"Danger"
					);
					return;
				}
				let subscribed = await prepareSW(userData);
				if(subscribed) {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Notifications Activated</span>
						</span>,
						`Notifications successfully activated.`,
						"Success"
					);
					setNotificationStatus(true);
				} else {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Notifications Failed</span>
						</span>,
						`Notifications could not activate.`,
						"Danger"
					);
				}
			} else {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Not Supported</span>
					</span>,
					`Notifications are supported in web apps saved to the home screen`,
					"Danger"
				);
			}
        } catch (err) {
            console.error(err);
        }
    };
	const handleUnregister = async () => {
		setNotificationStatus(false);
        return await unregister(userData);
    }

	// const { i18n } = useTranslation();

	// const changeLanguage = (lng: ILang['key']['lng']) => {
	// 	i18n.changeLanguage(lng).then();
	// 	showNotification(
	// 		<span className='d-flex align-items-center'>
	// 			<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
	// 			<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
	// 		</span>,
	// 		'You updated the language of the site. (Only "Aside" was prepared as an example.)',
	// 	);
	// };

	const removeUser = () => {
		if (setUser) {
			setUser('');
			sessionStorage.removeItem('trest_authUsername');
		}
		if (setUserData) {
			setUserData({});
			sessionStorage.removeItem('trest_authUser');
		}
		navigate(`../${appPagesMenu.login.path}`);
	}

	const logMeOut = () => {
		try {
			if (userData && userData.email && userData.token) {
				logout(userData.email, userData.token).then(res => {
					if (!res.Success) {
						console.log(res.Message);
					}
					removeUser();
				});
			} else {
				removeUser();
			}
		} catch (error) {
			removeUser();
		}
	}

	/**
	 * Language attribute
	 */
	// useLayoutEffect(() => {
	// 	document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
	// });

	const { setIsOpen } = useTour();

	return (
		<HeaderRight>
			<div className='g-3 d-flex justify-content-end'>
				{beforeChildren}
				{/* Tour Modal */}
				{!mobileDesign
				&& userData?.package 
				&& localStorage.getItem(`${userData.id}tourModalStarted`) === 'shown' 
				&& (
					<div className='col-auto position-relative'>
						<Popovers trigger='hover' desc='Quick "Trest.AI" tour'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon='MenuBook'
								onClick={() => setIsOpen(true)}
								aria-label='Quick "Trest.AI" tour'
							/>
						</Popovers>
					</div>
				)}

				{/*	Notifications */}
				{userData?.package && (
					<div className='col-auto mx-2'>
						<Popovers trigger='hover' desc={notificationStatus ? 'Notifications active' : 'Activate notifications'}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								onClick={() => handleNotificationClick()}
								className='btn-only-icon'
								data-tour='notifications'
								aria-label='Notifications'>
								<Icon
									icon={notificationStatus ? 'Notifications' : 'NotificationsOff'}
									color={notificationStatus ? 'success' : darkModeStatus ? 'light' : 'dark' }
									className='btn-icon'
								/>
							</Button>
						</Popovers>
					</div>
				)}

				{/* Dark Mode */}
				<div className='col-auto mx-2'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'
							aria-label='Toggle dark mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>

				{/*	Full Screen */}
				{!mobileDesign && (
					<div className='col-auto mx-2'>
						<Popovers trigger='hover' desc='Fullscreen'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
								onClick={() => setFullScreenStatus(!fullScreenStatus)}
								aria-label='Toggle fullscreen'
							/>
						</Popovers>
					</div>
				)}

				{/* Lang Selector */}
				{/* <div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							{typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon ===
							'undefined' ? (
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									className='btn-only-icon'
									aria-label='Change language'
									data-tour='lang-selector'>
									<Spinner isSmall inButton='onlyIcon' isGrow />
								</Button>
							) : (
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon={
										getLangWithKey(i18n.language as ILang['key']['lng'])?.icon
									}
									aria-label='Change language'
									data-tour='lang-selector'
								/>
							)}
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button
										icon={LANG[i].icon}
										onClick={() => changeLanguage(LANG[i].lng)}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div> */}

				{/*	Logout */}
				<div className='col-auto ms-2'>
					<Popovers trigger='hover' desc='Secure Logout'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={'Logout'}
							onClick={() => logMeOut()}
							aria-label='Secure Logout'
						/>
					</Popovers>
				</div>
				{afterChildren}
			</div>

			<ConfirmModal title={modalTitle} 
						message={modalMessage} 
						open={showModal} 
						openFunction={setShowModal} 
						callback={handleUnregister} >
			</ConfirmModal>

			{/* <OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Notifications.</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
						4 new components added.
					</Alert>
					<Alert icon='ThumbUp' isLight color='warning' className='flex-nowrap'>
						New products added to stock.
					</Alert>
					<Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
						There are products that need to be packaged.
					</Alert>
					<Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
						Your food order is waiting for you at the consultation.
					</Alert>
					<Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
						Escalator will turn off at 6:00 pm.
					</Alert>
					<div>
						<button
							onClick={handleSubscribe}
							disabled={!!subscription}
						>
							Subscribe
						</button>
						<button
							onClick={() => handlePush(subscription, userData)}
							disabled={!subscription}
						>
							Send Notification
						</button>
						<button onClick={handleUnregister}>
							Unregister SW
						</button>
					</div>
				</OffCanvasBody>
			</OffCanvas> */}
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
