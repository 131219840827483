import dayjs from "dayjs";
import { IPackageData } from "../data/packagesData";
import { IUserModel, IUserPackage } from "../data/user";

export function packageExpirationDate (user: Partial<IUserModel>): Date {
    let packExpDate = dayjs().add(30, 'day').toDate();
    if (user.package && user.package?.post_limit > 0) {
        packExpDate = dayjs(user.package.activated_date).add(user.package.post_limit, 'day').toDate();
    }
    return packExpDate;
}

export function packageRemainingTime (user: Partial<IUserModel>, unit?: dayjs.QUnitType | dayjs.OpUnitType): number {
    let packageExpires = packageExpirationDate(user);
    return dayjs(packageExpires).diff(dayjs(), unit || 'hour');
}

export function isPackageExpired (user: Partial<IUserModel>): boolean {
    return packageRemainingTime(user, 'minutes') < 0;
}

export function packageTransform(pack: IPackageData): IUserPackage {
    return {
        code: pack.code,
        name: pack.title,
        activated_date: new Date(),
        back_limit: pack.backLimit,
        post_limit: pack.postLimit,
        max_portfolio: pack.maxPortfolio,
        max_coin: pack.maxCoin,
        max_integration: pack.maxIntegration,
    } as IUserPackage;
}