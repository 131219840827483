import React, { FC, useContext, useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import * as auth from '../../../common/function/auth/auth'
import Password from '../../../components/bootstrap/forms/Password';
import PhoneInput from '../../../components/PhoneInput';
import { appPagesMenu } from '../../../menu';
import { obscureText } from '../../../helpers/helpers';
import { loginUser } from '../../../common/data/user';

interface ILoginHeaderProps {
	isNewUser?: boolean;
	stepOne?: boolean;
	resetPass?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser, stepOne, resetPass }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account</div>
				{stepOne && (<div className='text-center h4 text-muted mb-5'>Sign up to get started earn more!</div>)}
			</>
		);
	}
	if (resetPass) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Password Reset</div>
				{stepOne && (<div className='text-center h4 text-muted mb-5'>Password reset will be available after SMS verification.</div>)}
			</>
		);	
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome</div>
			{stepOne && (<div className='text-center h4 text-muted mb-5'>Sign in to continue trest.ai!</div>)}	
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
	stepOne: true,
	resetPass: false
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const navigate = useNavigate();
	const { darkModeStatus } = useDarkMode();
	const { search } = useLocation();
	const { setUser, setUserData } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [formMessage, setFormMessage] = useState<string>('');
	const [infoMessage, setInfoMessage] = useState<string>('');
	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [signUpStatus, setSignUpStatus] = useState<boolean>(!!isSignUp);
	const [twoStep, setTwoStep] = useState<boolean>(false);
	const [timeLeft, setTimeLeft] = useState(120); // 120 seconds
	const [remindPwd, setRemindPwd] = useState<boolean>(false);
	const [resetPwd, setResetPwd] = useState<boolean>(false);
	const [packageCode, setPackageCode] = useState<string>(new URLSearchParams(search).get("subId") || '');

	const verifyButtonRef = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		timer = setInterval(() => {
			setTimeLeft((prevTime) => prevTime - 1);
		}, 1000);
		if (timeLeft === 0) {
		  clearInterval(timer);
		}
		return () => clearInterval(timer);
	}, [timeLeft]);

	const formatTime = (seconds: number): string => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
	};

	const formik = useFormik({
		// enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
			registerEmail: '',
			registerName: '',
			registerSurname: '',
			registerCountry: '',
			registerPhone: '',
			registerPassword: '',
			smsCode: '',
			verificationCode: '',
			resetPassword: '',
			packageCode: packageCode,
		},
		validate: (values) => {
			const errors: { 
				loginUsername?: string; 
				loginPassword?: string;
				registerEmail?: string;
				registerName?: string;
				registerSurname?: string;
				registerCountry?: string;
				registerPhone?: string;
				registerPassword?: string;
				smsCode?: string;
				resetPassword?: string;
			} = {};
			const rq = 'Required';
			const iea = 'Invalid email address';
			const ipw = 'Password must contain lowercase, uppercase, number, and at least 8 characters length.';
			const e_ptrn = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
			const p_ptrn = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/

			if (!values.loginUsername && (!signUpStatus || remindPwd)) {
				errors.loginUsername = rq;
			}

			if (!e_ptrn.test(values.loginUsername) && (!signUpStatus || remindPwd)) {
				errors.loginUsername = iea;
			}

			if (!values.loginPassword && (!signUpStatus && !remindPwd)) {
				errors.loginPassword = rq;
			}

			if (!p_ptrn.test(values.loginPassword) && (!signUpStatus && !remindPwd)) {
				errors.loginPassword = ipw;
			}

			if (!values.resetPassword && (!signUpStatus && resetPwd)) {
				errors.resetPassword = rq;
			} 
			
			if (!p_ptrn.test(values.resetPassword) && (!signUpStatus && resetPwd)) {
				errors.resetPassword = ipw;
			}

			if (!values.registerEmail && signUpStatus) {
				errors.registerEmail = rq;
			}  
			
			if (!e_ptrn.test(values.registerEmail) && signUpStatus) {
				errors.registerEmail = iea;
			}

			if (!values.registerName && signUpStatus) {
				errors.registerName = rq;
			}

			if (!values.registerSurname && signUpStatus) {
				errors.registerSurname = rq;
			}

			if ((!values.registerPhone || !values.registerCountry) && signUpStatus) {
				errors.registerPhone = rq;
			}

			if (!values.registerPassword && signUpStatus) {
				errors.registerPassword = rq;
			} 
			
			if (!p_ptrn.test(values.registerPassword) && signUpStatus) {
				errors.registerPassword = ipw;
			}

			if (!values.smsCode && twoStep) {
				errors.smsCode = rq;
			}
			
			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			return;
		},
	});

	const handleContinue = () => {
		formik.validateForm();
		if (!formik.isValid && formik.errors) {
			// let msg = '';
			// Object.keys(formik.errors).forEach(e => {
			// 	const v = formik.errors[e as keyof typeof formik.errors];
			// 	if (v) {
			// 		msg += `${v}`
			// 	}
			// });
			// if(msg) {
			// 	setFormMessage(msg);
			// 	return;
			// }
			return;
		}

		setIsLoading(true);
		setFormMessage('');
		formik.setFieldValue('smsCode', '');
		
		if (signUpStatus) {
			auth.register(formik.values).then(res => {
				if (res.Success) {
					formik.values.verificationCode = res.Message;
					setFormMessage('');
					setTwoStep(true);
					setTimeLeft(120);
				} else {
					setTwoStep(false);
					setFormMessage(res.Message);
				}
				setIsLoading(false);
			});
		} else {
			if (remindPwd) {
				auth.resetPassword(formik.values).then(res => {
					if (res.Success) {
						formik.values.verificationCode = res.Message;
						setFormMessage('');
						setTwoStep(true);
						setTimeLeft(120);
					} else {
						setTwoStep(false);
						setFormMessage(res.Message);
					}
					setIsLoading(false);
				});
			} else {
				auth.login(formik.values).then(res => {
					if (res.Success) {
						formik.values.verificationCode = res.Message;
						setFormMessage('');
						setTwoStep(true);
						formik.setFieldTouched('smsCode', true);
						setTimeLeft(120);
					} else {
						setTwoStep(false);
						setFormMessage(res.Message);
					}
					setIsLoading(false);
				});
			}
		}
	};

	const handleVerify = () => {
		formik.validateForm();
		if (!formik.isValid && formik.errors) {
			let eFlag = false;
			Object.keys(formik.errors).forEach(e => {
				const v = formik.errors[e as keyof typeof formik.errors];
				if (v) 
					eFlag = true;
			});
			if(eFlag)
				return;
		}

		setIsLoading(true);
		if (remindPwd) { 
			formik.setFieldValue('resetPassword', '');
			formik.setFieldError('resetPassword', '');
			setResetPwd(true);
		}
		try {
			auth.verifyCode(formik.values).then(res => {
				if (res.Success) {
					setFormMessage('');
					if (!remindPwd)
						setAppUser(res, 100);
				} else {
					setFormMessage(res.Message);
				}
				setIsLoading(false);
			})
		} catch (err: any) {
			if (!(err instanceof Error)) {
				err = new Error(err);
			}
			setFormMessage(`${err.name}: ${err.message}`);
		}
	}

	const passwordReset = () => {
		formik.validateForm();
		if (!formik.isValid)
			return;

		setIsLoading(true);

		auth.renewPassword(formik.values).then(res => {
			if (res.Success) {
				setInfoMessage("Your password has been changed. You'll be taken to the app in a few seconds.");
				setAppUser(res, 5000);
			} else {
				setInfoMessage('');
				setFormMessage(res.Message);
			}
			setIsLoading(false);
		});
	}

	const sendSMS = () => {
		setFormMessage('');
		formik.setFieldValue('smsCode', '');
		setIsLoading(true);
		auth.resendSMS(formik.values).then(res => {
			if (res.Success) {
				formik.values.verificationCode = res.Message;
				setTimeLeft(120);
			} else {
				setFormMessage(res.Message);
			}
			setIsLoading(false);
		});
	}

	const handleLogin = () => {
		setSignInPassword(false);
		setSignUpStatus(false);
		setFormMessage('');
	}

	const handleRegister = () => {
		setSignInPassword(false);
		setSignUpStatus(true);
		setFormMessage('');
	}

	const setAppUser = (res: any, delay: number) => {
		if (setUser) {
			setUser(res.User.email);
		}
		if (setUserData) {
			loginUser.id = res.User.account_code; 
			loginUser.email = res.User.email;
			loginUser.isExpired = res.User.expired; 
			loginUser.isOnline = true;
			loginUser.name = res.User.first_name;
			loginUser.phone = res.User.phone;
			loginUser.src = res.User.image_url; 
			loginUser.srcSet = res.User.image_url;
			loginUser.surname = res.User.last_name;
			loginUser.package = res.User.package;
			loginUser.token = res.Token;
			loginUser.clientSecret = '';
			loginUser.subscriptionStatus = res.User.subscription_status;
			loginUser.notificationDevices = res.User.notification_endpoints;
			loginUser.packageCode = packageCode;
			setUserData(loginUser); 
		}
		if (!res.User.package || 
			['registered','incomplete','incomplete_expired','past_due','unpaid'].includes(res.User.subscription_status)
		) {
			navigate(`../../${appPagesMenu.subscription.path}`);
		} else {
			setTimeout(() => {
				navigate('/'); 	
			}, delay);
		}
	}

	useEffect(() => {
		if(packageCode) {
			setSignUpStatus(true);
			handleRegister();
		}
		return () => {}
	}, [packageCode])

	return (
		<PageWrapper
			isProtected={false}
			title={signUpStatus ? 'Trest.ai Sign Up' : 'Trest.ai Login'}
			className={classNames({ 'bg-dark': darkModeStatus, 'bg-light': !darkModeStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='trest.ai'>
										<Logo width={200} height={200} />
									</Link>
								</div>
								{(!twoStep && !remindPwd) && (
									<div
										className={classNames('rounded-3', {
											'bg-l10-dark': !darkModeStatus,
											'bg-dark': darkModeStatus,
										})}>
										<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
											<div className='col'>
												<Button
													color={darkModeStatus ? 'light' : 'dark'}
													isLight={signUpStatus}
													className='rounded-0 w-100'
													size='lg'
													onClick={handleLogin}>
													Login
												</Button>
											</div>
											<div className='col'>
												<Button
													color={darkModeStatus ? 'light' : 'dark'}
													isLight={!signUpStatus}
													className='rounded-0 w-100'
													size='lg'
													onClick={handleRegister}>
													Sign Up
												</Button>
											</div>
										</div>
									</div>
								)}

								<LoginHeader isNewUser={signUpStatus} stepOne={!twoStep} resetPass={remindPwd} />
								
								{formMessage.length > 0 ? (
									<Alert isLight icon='Lock' color={'danger'} borderWidth={2}>
										<div className='row'>
											<div className='col-12'>
												<strong>{formMessage}</strong> 
											</div>
										</div>
									</Alert> 
								) : null }
								
								{infoMessage.length > 0 ? (
									<Alert isLight icon='Info' color={'success'} borderWidth={2}>
										<div className='row'>
											<div className='col-12'>
												<strong>{infoMessage}</strong> 
											</div>
										</div>
									</Alert> 
								) : null }
								<form className='row g-4'>
									{!twoStep ? (
										<div>
											{signUpStatus ? (
												<>
													<div className='col-12 mb-2'>
														<FormGroup
															id='registerEmail'
															isFloating
															label='Email'
															className={classNames({
																'd-none': signInPassword,
															})}>
															<Input 
																autoFocus={true}
																autoComplete='email' 
																autoCapitalize='none'
																inputMode='email'
																type='email' 
																value={formik.values.registerEmail}
																invalidFeedback={
																	formik.errors.registerEmail
																}
																isTouched={formik.touched.registerEmail}
																isValid={formik.isValid}
																onChange={formik.handleChange} 
																onBlur={formik.handleBlur}
																onFocus={() => {
																	formik.setFieldError('registerEmail', '');
																}}
																onKeyDown={(e) => { 
																	if(e.key === 'Enter' ) {
																		e.preventDefault(); 
																		handleContinue();
																	}
																}}
																/>
														</FormGroup>
													</div>
													<div className='col-12 mb-2'>
														<FormGroup
															id='registerName'
															isFloating
															label='Name'>
															<Input autoComplete='given-name' 
																isTouched={formik.touched.registerName}
																invalidFeedback={
																	formik.errors.registerName
																}
																isValid={formik.isValid}
																value={formik.values.registerName}
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																onFocus={() => {
																	formik.setFieldError('registerName', '');
																}}
																onKeyDown={(e) => { 
																	if(e.key === 'Enter' ) {
																		e.preventDefault(); 
																		handleContinue();
																	}
																}}
															/>
														</FormGroup>
													</div>
													<div className='col-12 mb-2'>
														<FormGroup
															id='registerSurname'
															isFloating
															label='Surname'>
															<Input autoComplete='family-name' 
																isTouched={formik.touched.registerSurname}
																invalidFeedback={
																	formik.errors.registerSurname
																}
																isValid={formik.isValid}
																value={formik.values.registerSurname}
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																onFocus={() => {
																	formik.setFieldError('registerSurname', '');
																}}
																onKeyDown={(e) => { 
																	if(e.key === 'Enter' ) {
																		e.preventDefault(); 
																		handleContinue();
																	}
																}}
															/>
														</FormGroup>
													</div>
													<div className='col-12 mb-2'>
														<PhoneInput 
															id='registerPhone'
															required
															isFloating
															autoComplete='phone'
															inputMode='tel'
															label='Phone (SMS verification)'
															isTouched={formik.touched.registerPhone}
															invalidFeedback={
																formik.errors.registerPhone
															}
															isValid={formik.isValid}
															onChange={(e, c, p) => {
																formik.setFieldValue('registerCountry', c);
																formik.handleChange(e);
															}}
															value={formik.values.registerPhone}
															onBlur={formik.handleBlur}
															onFocus={() => {
																formik.setFieldError('registerPhone', '');
															}}
															onKeyDown={(e) => { 
																if(e.key === 'Enter' ) {
																	e.preventDefault(); 
																	handleContinue();
																}
															}}
														/>
													</div>
													<div className='col-12 mb-2'>
														<Password
															isFloating
															required
															label='Password'
															id='registerPassword'
															type={'password'}
															autoComplete='new-password'
															autoCapitalize='none'
															isTouched={formik.touched.registerPassword}
															invalidFeedback={
																formik.errors.registerPassword
															}
															isValid={formik.isValid}
															value={formik.values.registerPassword}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															onFocus={() => {
																formik.setFieldError('registerPassword', '');
															}}
															onKeyDown={(e) => { 
																if(e.key === 'Enter' ) {
																	e.preventDefault(); 
																	handleContinue();
																}
															}}
															>
														</Password>
													</div>
												</>
											) : (
												<>
													<div className='col-12 mb-2'>
														<FormGroup
															id='loginUsername'
															isFloating
															label='Your email'
															className={classNames({
																'd-none': signInPassword,
															})}>
															<Input
																autoFocus={true}
																autoComplete='username'
																autoCapitalize='none'
																inputMode='email'
																value={formik.values.loginUsername}
																isTouched={formik.touched.loginUsername}
																invalidFeedback={
																	formik.errors.loginUsername
																}
																// isValid={formik.isValid}
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																onFocus={() => {
																	formik.setFieldError('loginUsername', '');
																}}
																onKeyDown={(e) => { 
																	if(e.key === 'Enter' ) {
																		e.preventDefault(); 
																		handleContinue();
																	}
																}}
															/>
														</FormGroup>
													</div>
													{!remindPwd && (
														<div className='col-12 mb-2'>
															<Password
																isFloating
																required
																label='Password'
																id='loginPassword'
																type={'password'}
																autoComplete='current-password'
																autoCapitalize='none'
																isTouched={formik.touched.loginPassword}
																invalidFeedback={
																	formik.errors.loginPassword
																}
																// isValid={formik.isValid}
																value={formik.values.loginPassword}
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																onFocus={() => {
																	formik.setFieldError('loginPassword', '');
																}}
																onKeyDown={(e) => { 
																	if(e.key === 'Enter' ) {
																		e.preventDefault(); 
																		handleContinue();
																	}
																}}
																>
															</Password>
														</div>
													)}
													{!remindPwd && (
														<div className='text-end'>
															<Button
																color='link'
																className='py-3'
																isLink={true}
																onClick={() => {
																	setInfoMessage('');
																	setRemindPwd(true);
																}}>
																Forgot Password?
															</Button>
														</div>
													)}
												</>
											)}
										</div>
									) : null }

									{/* Begin 2 Step Verification */}
									{twoStep ? (
										<>
											<div className='col-12'>
												{resetPwd && remindPwd && (
													<>
														<div className='text-center h5 mb-3'>
															Type your new password
														</div>
														<div className='col-12 mb-5'>
															<Password
																isFloating
																required
																label='New Password'
																id='resetPassword'
																type={'password'}
																autoComplete='new-password'
																autoCapitalize='none'
																isTouched={formik.touched.resetPassword}
																invalidFeedback={
																	formik.errors.resetPassword
																}
																isValid={formik.isValid}
																value={formik.values.resetPassword}
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																onFocus={() => {
																	formik.setFieldError('resetPassword', '');
																}}
																onKeyDown={(e) => { 
																	if(e.key === 'Enter' ) {
																		e.preventDefault(); 
																		passwordReset();
																	}
																}}
																>
															</Password>
														</div>
														<div className='col-12 mb-2'>
															<Button
																rounded={0}
																color='primary'
																className='w-100 py-3'
																isDisable={!formik.values.resetPassword}
																onClick={passwordReset}>
																{isLoading && (
																	<Spinner isSmall inButton isGrow />
																)}
																Reset Password
															</Button>
														</div>
													</>
												)}
												{!resetPwd && (
													<>
														<div className='text-center h5 mb-3'>
															Enter verification code <br />
															sent to your mobile as step 2 of 2 <br />
															<small>
																{formik.values.registerPhone ? 
																	`(${obscureText( formik.values.registerCountry + formik.values.registerPhone, 4, 2)})` : 
																	``}
															</small>
														</div>
														<div className='col-12 mb-3'>
															<FormGroup
																id='smsCode'
																isFloating
																label='6-digit SMS code'>
																<Input 
																	autoFocus={true}
																	inputMode='numeric'
																	autoComplete='one-time-code'
																	value={formik.values.smsCode}
																	isTouched={formik.touched.smsCode}
																	invalidFeedback={
																		formik.errors.smsCode
																	}
																	isValid={formik.isValid}
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	onFocus={() => {
																		formik.setFieldError('smsCode', '');
																	}}
																	onKeyDown={(e) => { 
																		if(e.key === 'Enter') {
																			e.preventDefault(); 
																			e.stopPropagation(); 
																			// handleVerify();
																			if (verifyButtonRef.current) {
																				verifyButtonRef.current.focus();
																				verifyButtonRef.current.click();
																			}
																		}
																	}}
																/>
															</FormGroup>
														</div>
														<div className='col-12'>
															<Button
																ref={verifyButtonRef}
																color='primary'
																className='w-100 py-3'
																rounded={1}
																isDisable={isLoading}
																onClick={() => {
																	handleVerify();
																}}>
																{isLoading && (
																	<Spinner isSmall inButton isGrow />
																)}
																Verify Code
															</Button>
														</div>
														<div className='text-center'>
															<Button
																isOutline
																isLink
																rounded={0}
																color={darkModeStatus ? 'light' : 'dark'}
																className={classNames('w-100 py-3 mt-5', {
																	'border-light': !darkModeStatus,
																	'border-dark': darkModeStatus,
																})}
																icon='Sms'
																isDisable={timeLeft > 0}
																onClick={() => {
																	setFormMessage('');
																	sendSMS();
																}}>
																Resend code {timeLeft > 0 ? 'in ' + formatTime(timeLeft) : ""}
															</Button>
														</div>
													</>
												)}
												
											</div>
										</>
									) : (
										<div className='col-12'>
											<Button
												color='primary'
												className='w-100 py-3'
												onClick={handleContinue}>
												{isLoading && (
													<Spinner isSmall inButton isGrow />
												)}
												{remindPwd ? "Reset Password" :
													(signUpStatus ? "Sign Up" : "Login")
												}
											</Button>
										</div>
									)}
									{(twoStep || remindPwd) && (
										<div className='text-center'>
											<Button
												isOutline
												isLink
												rounded={0}
												color={darkModeStatus ? 'light' : 'dark'}
												className={classNames('w-100 py-3 mt-1', {
													'border-light': !darkModeStatus,
													'border-dark': darkModeStatus,
												})}
												icon='ArrowBackIos'
												onClick={() => {
													setTwoStep(false);
													setRemindPwd(false);
													setResetPwd(false);
													setFormMessage('');
													setIsLoading(false);
												}}>
												Try with different account
											</Button>
										</div>
									)}

									{/* BEGIN :: Social Login */}
									{/* {!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>
												OR
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={googleSignIn}>
													Continue with Google
												</Button>
											</div>
										</>
									)} */}
									{/* END :: Social Login */}
								</form>
								{formMessage.length > 0 && (
									<div className='row'>
										<div className='col-12 text-end my-2'>
											<a href={`mailto:support@trest.ai?subject=trest.ai Login Help`} className='lh-lg'>Need help?</a>
										</div>
									</div>
								)}
							</CardBody>
						</Card>
						<div className='text-center'>
							<a href='https://trest.ai/privacy-policy' target='_blank'
								className={classNames('text-decoration-none', {
									'link-light': darkModeStatus,
									'link-dark': !darkModeStatus,
								})}>
								Privacy Policy</a>
							&nbsp;&nbsp;|&nbsp;&nbsp;
							<a href='https://trest.ai/terms-policy' target='_blank'
								className={classNames('link-light text-decoration-none', {
									'link-light': darkModeStatus,
									'link-dark': !darkModeStatus,
								})}>
								Terms & Conditions
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
