import axios from 'axios';
import trestAppConfig from "../../config/trestAppConfig";
import { isPackageExpired } from '../function/package';

export interface IUserPackage {
    code: string,
    name: string,
    activated_date: Date,
    back_limit: number,
    post_limit: number,
    max_portfolio: number,
    max_coin: number,
    max_integration: number,
}

export interface IUserModel {
    id: string;
	name: string;
	surname: string;
	email: string;
    phone: string;
	src: string;
	srcSet: string;
	isOnline: boolean;
    isExpired: boolean;
    package: IUserPackage;
    token: string;
    portfolioCount: number;
    packageCode: string;
    clientSecret: string;
    subscriptionStatus: string;
    notificationDevices: string[];
}

export const loginUser: IUserModel = {
    id: '',
    name: '',
    surname: '',
    email: '',
    phone: '',
    src: '',
    srcSet: '',
    isOnline: false,
    isExpired: false,
    package: {} as IUserPackage,
    token: '',
    portfolioCount: 0,
    packageCode: '',
    clientSecret: '',
    subscriptionStatus: '',
    notificationDevices: [],
};

export async function get(token: string) {
	let retVal = {
		Success: false,
		Message: '',
		User: {} as IUserModel,
	};
	const header = {
		'Authorization': trestAppConfig.authorization,
		'token': token
	}
	try {
		await axios.get(trestAppConfig.baseURL + "/user", { headers: header })
			.then((response: any) => {
				if (response.data.status == false) {
					if (response.data.error.length > 0) {
						retVal.Message = response.data.error;
					}
				} else {
					retVal.Success = true;
					retVal.User = {
						id: response.data.user.account_code,
						name: response.data.user.first_name,
						surname: response.data.user.last_name,
						email: response.data.user.email,
						phone: response.data.user.phone,
						src: response.data.user.image_url,
						srcSet: '',
						isOnline: true,
						isExpired: false,
						package: response.data.user.package as IUserPackage,
						token: token,
						portfolioCount: 0,
						packageCode: response.data.user.package?.code,
						clientSecret: '',
						subscriptionStatus: response.data.user.subscription_status,
						notificationDevices: response.data.user.notification_endpoints
					};
					retVal.User = {
						...retVal.User,
						isExpired: isPackageExpired(retVal.User)
					};
				};
			});
	} catch (err: any) {
        if (!(err instanceof Error)) {
            err = new Error(err);
        }
		retVal.Message = `${err.name}: ${err.message}`;
		console.log(err);
	}

	return retVal;
}

export async function update(frm: any, token: string) {
	let retVal = {
		'Success': false,
		'Message': '',
        'User': {} as any,
	};
	const header = {
		'Authorization': trestAppConfig.authorization,
		'token': token,
        'content-type': 'multipart/form-data',
	}
    const fd = new FormData();
    fd.append('first_name', frm.firstName);
    fd.append('last_name', frm.lastName);
	if(frm.image) {
    	fd.append('image', frm.image);
	}
    if (frm.oldPassword !== '' && frm.newPassword !== '') {
		fd.append('old_password', frm.oldPassword);
        fd.append('new_password', frm.newPassword);
	}
	try {
		await axios.post(trestAppConfig.baseURL + "/user/update", fd, { headers: header })
			.then((response: any) => {
				if (response.data.status == false) {
					if (response.data.error.length > 0) {
						retVal.Message = response.data.error;
					}
				} else {
					retVal.Success = true;
					retVal.User = response.data.user;
				}
			});
	} catch (err) {
		retVal.Message = 'Something went wrong. Please try again later.';
		console.log(err);
	}
	return retVal;
}
