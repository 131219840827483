import React, { FC } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from './bootstrap/Modal';
import Button from './bootstrap/Button';

interface IConfirmModalProps {
    title: string;
    message: string;
	open: boolean;
	openFunction: Function;
    callback: () => void;
}
const ConfirmModal: FC<IConfirmModalProps> = (props: IConfirmModalProps) => {
    const { title, message, open, openFunction, callback } = props;

    const handleOkClick = () => {
        openFunction(false);
        if (callback)
            callback();
    }
    
	return (
		<Modal
            allowEsc={false}
            isCentered={true}
            isStaticBackdrop={true}
            isOpen={open}
            setIsOpen={() => openFunction(open)}
            titleId="modal-title">
            <ModalHeader className='justify-content-center'>
                <ModalTitle id="modal-title" >
                    <div className='text-center d-flex'>
                        {title}
                    </div>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className='lead'>
                    {message}
                </div>
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button
                        color="info"
                        isLink={true}
                        onClick={() => openFunction(false)} 
                        className='me-3'>
                        Cancel
                    </Button>
                    <Button color='info' onClick={handleOkClick} > 
                        OK
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
	);
};

export default ConfirmModal;
