import React, { useContext, useEffect, useState } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import AuthContext from '../../../contexts/authContext';
import showNotification from '../../../components/extras/showNotification';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import Icon from '../../../components/icon/Icon';
import { packageRemainingTime } from '../../../common/function/package';
import Alert, { AlertLink } from '../../../components/bootstrap/Alert';
import { useNavigate } from 'react-router-dom';
import { appPagesMenu } from '../../../menu';

const DashboardHeader = () => {
	const navigate = useNavigate();
	const { userData } = useContext(AuthContext);

	const pRemTime = packageRemainingTime(userData, 'minute');
	const showPackageExpirationMessage = pRemTime < 72 * 60;
	const [expirationMessage, setExpirationMessage] = useState<string>('');
	const [showExpirationMessage, setShowExpirationMessage] = useState<boolean>(false);

	useEffect(() => {
		if (pRemTime > 0 ) {
			dayjs.extend(duration);
			setExpirationMessage(`Your package will need renewal in  
				${dayjs.duration(pRemTime, 'minute').humanize()}. `);
		} else {
			navigate(`../${appPagesMenu.renewPackage.path}`);
		}
		return () => {}
	}, [])
	
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (showPackageExpirationMessage) {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Package Expiration Warning</span>
					</span>,
					`${expirationMessage}`,
					"Danger"
				);
				setTimeout(() => setShowExpirationMessage(true), 6000);
			} else {
				clearTimeout(timeout);
			}
		}, 5000);
		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expirationMessage]);

	return (
		<Header>
			<HeaderLeft>
				<div className='d-inline-flex'>
					{showExpirationMessage ? (
						<Alert borderWidth={1} color="danger" isDismissible isLight className='mb-0' 
							onDismiss={(d: boolean) => setShowExpirationMessage(!d) }
						>
							{expirationMessage}
							<AlertLink href={`#`} className='ms-1' 
								onClick={()=>window.open('https://trest.ai/#sec_pricing','_blank', 'rel=noopener noreferrer')} >
								See Packages
							</AlertLink>
					  	</Alert>
					) : (
						`Earn More, Do Less with Trest.AI`
					)}
				</div>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DashboardHeader;
