import React, { FC } from 'react';
import PropTypes from 'prop-types';
import logoimg from '../assets/logos/trest.ai-logo-800.png'

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<img src={logoimg} 
			width={width || 200} 
			height={height || 200}
			alt='trest.ai' 
		/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
