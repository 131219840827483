import { FC, useState } from 'react';

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    src: string;
    srcSet?: string;
    alternateSrc?: string;
};
const Image: FC<ImageProps> = ({ src, srcSet, alternateSrc, ...props }) => {
    const [imgSrc, setImgSrc] = useState(src);

    const handleError = () => {
        if (alternateSrc) {
            setImgSrc(alternateSrc);
        } 
    }

    return (
        <img
            // srcSet={srcSet}
            src={imgSrc}
            onError={handleError} 
            {...props}
        />
    );
};

export default Image;
