import React, { ReactNode, useContext, useEffect, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {
	dashboardPagesMenu,
} from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import AuthContext from '../../../contexts/authContext';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const { t, i18n } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();

	const { userData } = useContext(AuthContext);

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		i18n.changeLanguage('en').then(); //default language
		document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
	}, [])

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<>
					<Navigation menu={dashboardPagesMenu} id='aside-dashboard' data-tour='left-menu'
						className={classNames( 
							{
								'text-dark': !darkModeStatus,
								'text-light': darkModeStatus,
							})} />
					{/* <NavigationLine /> */}
				</>
			</AsideBody>
			<AsideFoot>
				{asideStatus ? (
					<div data-tour='support-menu' 
						className={classNames('text-center m-2 lh-base rounded shadow', 
								{
									'bg-l10-primary': !darkModeStatus,
									'bg-lo25-info': darkModeStatus,
								})} style={{width: 182}}>
						{/* <Icon
							icon='Circle'
							className={classNames(
								'position-absolute start-5',
								'text-success',
								'animate__animated animate__heartBeat animate__infinite animate__slower',
							)}
						/> */}
						<Icon icon='SupportAgent' size='3x' className='me-1' /> <br />
						If you have any questions, feedback, or need assistance with trest.ai, we're just an email away: <br /> 
						<a href={`mailto:support@trest.ai?subject=trest.ai Help Request - ${userData.name}`} className='lh-lg'>support@trest.ai</a> <br />
						We'll get back to you as soon as possible.
					</div>
				) : (
					<div className='w-100 text-center' data-tour='support-menu' >
						<button
							type='button'
							className='btn brand-aside-toggle text-center'
							aria-label='Toggle Aside'
							onClick={() => setAsideStatus(!asideStatus)}>
							<Icon icon='SupportAgent' size={'2x'} className='brand-aside-toggle-close text-center' />
							<Icon icon='SupportAgent' size={'2x'} className='brand-aside-toggle-open text-center' />
						</button>
					</div>
				)}
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
