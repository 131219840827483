import React from 'react';
import { useTour } from '@reactour/tour';
import { createUseStyles } from 'react-jss';
import Button from './components/bootstrap/Button';

import dark_mode from './assets/tour/dark_mode.svg'
import notifications from './assets/tour/notifications.svg'
import left_menu from './assets/tour/left_menu.svg'
import support_here from './assets/tour/support_here.svg'
import user_profile from './assets/tour/profile.svg'

/**
 * Steps style
 */
export const styles = {
	/**
	 * Overlay style
	 * @param base
	 */
	maskWrapper: (base: any) => ({ ...base }),
	maskArea: (base: any) => ({
		...base,
		rx: 'var(--bs-border-radius)',
	}),
	highlightedArea: (base: any) => ({ ...base }),
	badge: (base: any) => ({ ...base }),
	popover: (base: any) => ({
		...base,
		boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)',
		backgroundColor: 'var(--bs-body-bg)',
		color: 'var(--bs-body-color)',
		borderRadius: 'var(--bs-border-radius)',
	}),
};

/**
 * Image Styles
 * @type {(data?: {theme?: DefaultTheme}) => Classes<"image">}
 */
const useStyles = createUseStyles({
	image: {
		maxHeight: '150px',
		objectFit: 'contain',
	},
});

/**
 * Prev & Next buttons
 * @returns {JSX.Element}
 * @constructor
 */
const TourNavigation = () => {
	const { currentStep, setCurrentStep } = useTour();
	return (
		<div className='col-12 mt-3'>
			<div className='row'>
				<div className='col'>
					{!!currentStep && (
						<Button
							icon='ArrowBackIos'
							color='info'
							isLink
							onClick={() => setCurrentStep(currentStep - 1)}>
							Prev
						</Button>
					)}
				</div>
				<div className='col-auto'>
					<Button
						icon='ArrowForwardIos'
						color='info'
						isLight
						onClick={() => setCurrentStep(currentStep + 1)}>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
};

/**
 * Selector
 * @param name
 * @returns {`[data-tour='${string}']`}
 */
const getTargetName = (name: string): `[data-tour='${string}']` => {
	return `[data-tour='${name}']`;
};

const DarkModeTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-5'>
				<img src={dark_mode} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-7 d-flex align-items-center'>
				<div>
					<p className='lead'>Dark / Light Mode</p>
					<p>You can switch between dark and light mode.</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const NotificationTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-5'>
				<img src={notifications} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-7 d-flex align-items-center'>
				<div>
					<p className='lead'>Notifications</p>
					<p>You can activate browser notifications to get buy/sell alerts.</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const LeftMenuTour = () => {
	const classes = useStyles();
	return (
		<>
			<div className='row'>
				<div className='col-md-5'>
					<img src={left_menu} className={classes.image} width='100%' alt='' />
				</div>
				<div className='col-md-7 d-flex align-items-center'>
					<p className='lead'>Navigation</p>
				</div>
			</div>
			<div>
				<div className='col-md-12 d-flex align-items-center pt-2'>
					<div>
						<p>You can navigate to these screens.</p>
						<ol>
							<li>
								<b>Dashboard:</b> Where you can monitor market trends, buy/sell alerts, and key insights at a glance.
							</li>
							<li>
								<b>Portfolio List:</b> Manage and monitor all your portfolios in one place.
							</li>
							<li>
								<b>Buy/Sell Alerts:</b> Access real-time buy and sell alerts to stay on top of market movements.
							</li>
							<li>
								<b>Backtesting:</b> Test and analyze your trading strategies over time.
							</li>
							<li>
								<b>Transactions:</b> View and manage all your deposits, withdrawals, and trades.
							</li>
							<li>
								<b>Markets Overview:</b> See real-time prices and market insights of listed cryptocurrencies.
							</li>
						</ol>
					</div>
				</div>
				<TourNavigation />
			</div>
		</>
	);
};

const SupportTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-5'>
				<img src={support_here} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-7 d-flex align-items-center'>
				<div>
					<p className='lead'>Support</p>
					<p>Our support is here to help.</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const ProfileTour = () => {
	const { setIsOpen, setCurrentStep } = useTour();
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-5'>
				<img src={user_profile} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-7 d-flex align-items-center'>
				<div>
					<p className='lead'>Profile</p>
					<p>You can find your profile details here.</p>
				</div>
			</div>
			<div className='row'>
				<Button
					color='success'
					isLight
					onClick={() => {
						setIsOpen(false);
						setCurrentStep(0);
					}}>
					Let's get start!
				</Button>
			</div>
		</div>
	);
};


/**
 * Tour Steps
 */
const steps = [
	/**
	 * Dark Mode
	 * @step 0
	 */
	{
		selector: getTargetName('dark-mode'),
		content: () => <DarkModeTour />,
	},
	/**
	 * Lang Selector
	 * @step 1
	 */
	{
		selector: getTargetName('notifications'),
		content: () => <NotificationTour />,
		highlightedSelectors: [getTargetName('notifications')],
		mutationObservables: [getTargetName('notifications')],
		resizeObservables: [getTargetName('notifications')],
	},
	/**
	 * Left menu
	 * @step 2
	 */
	{
		selector: getTargetName('left-menu'),
		content: () => <LeftMenuTour />,
		highlightedSelectors: [getTargetName('left-menu')],
		mutationObservables: [getTargetName('left-menu')],
		resizeObservables: [getTargetName('left-menu')],
	},
	/**
	 * Support
	 * @step 3
	 */
	{
		selector: getTargetName('support-menu'),
		content: () => <SupportTour />,
		highlightedSelectors: [getTargetName('support-menu')],
		mutationObservables: [getTargetName('support-menu')],
		resizeObservables: [getTargetName('support-menu')],
	},
	/**
	 * Profile
	 * @step 4
	 */
	{
		selector: getTargetName('user-profile'),
		content: () => <ProfileTour />,
		highlightedSelectors: [getTargetName('user-profile')],
		mutationObservables: [getTargetName('user-profile')],
		resizeObservables: [getTargetName('user-profile')],
	},
	/**
	 * List Page
	 * @step 5
	 */
	// {
	// 	selector: '#aside-demo-pages__listPages--link',
	// 	content: () => <ListPageTour />,
	// 	highlightedSelectors: ['#aside-demo-pages__listPages'],
	// 	mutationObservables: ['#aside-demo-pages__listPages'],
	// 	resizeObservables: ['#aside-demo-pages__listPages'],
	// },
	
	/**
	 * Auth Page
	 * @step 10
	 */
	// {
	// 	selector: '[href="/auth-pages/login"]',
	// 	content: () => <AuthPageTour />,
	// 	highlightedSelectors: [
	// 		'[href="/auth-pages/login"]',
	// 		'[href="/auth-pages/sign-up"]',
	// 		'[href="/auth-pages/404"]',
	// 		getTargetName('login-page'),
	// 	],
	// 	mutationObservables: [
	// 		'[href="/auth-pages/login"]',
	// 		'[href="/auth-pages/sign-up"]',
	// 		'[href="/auth-pages/404"]',
	// 		getTargetName('login-page'),
	// 	],
	// 	resizeObservables: [
	// 		'[href="/auth-pages/login"]',
	// 		'[href="/auth-pages/sign-up"]',
	// 		'[href="/auth-pages/404"]',
	// 		getTargetName('login-page'),
	// 	],
	// },
	
	/**
	 * Project Item
	 * @step 13
	 */
	// {
	// 	selector: getTargetName('Mail App'),
	// 	content: () => <ProjectItemTour />,
	// 	highlightedSelectors: [getTargetName('Mail App'), getTargetName('mail-app-modal')],
	// 	mutationObservables: [getTargetName('Mail App'), getTargetName('mail-app-modal')],
	// 	resizeObservables: [getTargetName('Mail App'), getTargetName('mail-app-modal')],
	// },
	
	/**
	 * Last
	 * @step 22
	 */
	// {
	// 	selector: 'body',
	// 	content: () => <LastTour />,
	// },
];

export default steps;
