import axios from 'axios';
import trestAppConfig from "../../../config/trestAppConfig";
// import { sha256 } from 'js-sha256';
// import dayjs from 'dayjs';

export async function register(frm: any) {
	let retVal = {
		'Success': false,
		'Message': ''
	};
	const header = {
		'Authorization': trestAppConfig.authorization
	}
	const d = {
		'email': frm.registerEmail,
		'first_name': frm.registerName,
		'last_name': frm.registerSurname,
		'phone': `${frm.registerCountry}${frm.registerPhone}`,
		'password': frm.registerPassword,
		'package_code': frm.packageCode,
	}
	try {
		await axios.post(trestAppConfig.baseURL + "/user/create", d, { headers: header })
			.then((response: any) => {
				if (response.data.status == false) {
					if (response.data.error.length > 0) {
						retVal.Message = response.data.error;
					}
				} else {
					retVal.Success = true;
					retVal.Message = response.data.pin_code;
				}
			});
	} catch (err) {
		retVal.Message = 'Something went wrong. Please try again later.';
		console.log(err);
	}
	return retVal;
}

export async function login(frm: any) {
	let retVal = {
		'Success': false,
		'Message': ''
	};
	const header = {
		'Authorization': trestAppConfig.authorization
	}
	const d = {
		'email': frm.loginUsername,
		'password': frm.loginPassword
	}
	try {
		await axios.post(trestAppConfig.baseURL + "/user/login", d, { headers: header })
			.then((response: any) => {
				if (response.data.status == false) {
					if (response.data.error.length > 0) {
						retVal.Message = response.data.error;
					}
				} else {
					retVal.Success = true;
					retVal.Message = response.data.pin_code;
				}
			});
	} catch (err) {
		retVal.Message = 'Something went wrong. Please try again later.';
		console.log(err);
	}
	return retVal;
}


export async function verifyCode(frm: any) {
	let retVal = {
		'Success': false,
		'Message': '',
		'User': {} as any,
		'Token': ''
	};
	const header = {
		'Authorization': trestAppConfig.authorization
	}
	const d = {
		'pin': frm.smsCode,
		'code': frm.verificationCode,
	}
	try {
		await axios.post(trestAppConfig.baseURL + "/user/sms/enter", d, { headers: header })
			.then((response: any) => {
				if (response.data.status == false) {
					if (response.data.error.length > 0) {
						retVal.Message = response.data.error;
					}
				} else if (response.data.status == response.data.expired) {
					retVal.User = response.data.user;
					retVal.Message = 'User expired. Please contact to customer service.';
				} else {
					retVal.Success = true;
					retVal.Token = response.data.token;
					retVal.User = response.data.user;
					retVal.User.isExpired = response.data.expired;
				}
			});
	} catch (err: any) {
		if (!(err instanceof Error)) {
			err = new Error(err);
		}
		retVal.Message = `${err.name}: ${err.message}`;
		console.log(err);
	}
	return retVal;
}

export async function resendSMS(frm: any) {
	let retVal = {
		'Success': false,
		'Message': ''
	};
	const header = {
		'Authorization': trestAppConfig.authorization
	}
	const d = {
		'email': frm.loginUsername ? frm.loginUsername : frm.registerEmail, 
		'pin_code': frm.verificationCode, 
	}
	try {
		await axios.post(trestAppConfig.baseURL + "/user/sms/resend", d, { headers: header })
			.then((response: any) => {
				if (response.data.status == false) {
					if (response.data.error.length > 0) {
						retVal.Message = response.data.error;
					}
				} else {
					retVal.Success = true;
					retVal.Message = response.data.pin_code;
				}
			});
	} catch (err) {
		retVal.Message = 'Something went wrong. Please try again later.';
		console.log(err);
	}
	return retVal;
}

export async function resetPassword(frm: any) {
	let retVal = {
		'Success': false,
		'Message': ''
	};
	const header = {
		'Authorization': trestAppConfig.authorization
	}
	const d = {
		'email': frm.loginUsername
	}
	try {
		await axios.post(trestAppConfig.baseURL + "/user/password/reset", d, { headers: header })
			.then((response: any) => {
				if (response.data.status == false) {
					if (response.data.error.length > 0) {
						retVal.Message = response.data.error;
					}
				} else {
					retVal.Success = true;
					retVal.Message = response.data.pin_code;
				}
			});
	} catch (err) {
		retVal.Message = 'Something went wrong. Please try again later.';
		console.log(err);
	}
	return retVal;
}

export async function renewPassword(frm: any) {
	let retVal = {
		'Success': false,
		'Message': '',
		'User': {} as any,
		'Token': ''
	};
	const header = {
		'Authorization': trestAppConfig.authorization
	}
	const d = {
		'email': frm.loginUsername,
        'password': frm.resetPassword
	}
	try {
		await axios.post(trestAppConfig.baseURL + "/user/password/renew", d, { headers: header })
			.then((response: any) => {
				if (response.data.status == false) {
					if (response.data.error.length > 0) {
						retVal.Message = response.data.error;
					}
				} else {
					retVal.Success = true;
					retVal.Token = response.data.token;
					retVal.User = response.data.user;
					retVal.User.isExpired = response.data.expired;
				}
			});
	} catch (err) {
		retVal.Message = 'Something went wrong. Please try again later.';
		console.log(err);
	}
	return retVal;
}


export async function logout(email: string, token: string) {
	let retVal = {
		'Success': false,
		'Message': ''
	};
	const header = {
		'Authorization': trestAppConfig.authorization,
		'token': token,
	}
	const d = {
		'email': email
	}
	try {
		await axios.post(trestAppConfig.baseURL + "/user/logout", d, { headers: header })
			.then((response: any) => {
				if (response.data.status == false) {
					if (response.data.error.length > 0) {
						retVal.Message = response.data.error;
					}
				} else {
					retVal.Success = true;
				}
			});
	} catch (err) {
		retVal.Message = 'Something went wrong. Please try again later.';
		console.log(err);
	}
	return retVal;
}